<template>
  <b-card no-body>
    <b-card-body class="p-0">
      <b-tabs content-class="pl-1 pr-1 pl-sm-2 pr-sm-2" nav-class="pl-1 pr-1 pl-sm-2 pr-sm-2" justified>

        <b-tab v-for="tab in tabs" :key="tab.index">
          <template #title>
            <font-awesome-icon :icon="tab.icon" class="m-0" fixed-width />
            <span class="font-weight-bold d-none d-md-inline ml-50">{{
              $t(tab.title)}}</span>
          </template>
          <component :is="tab.component" :options="options" :account="selectedAccount" />
        </b-tab>

      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BTabs, BTab,
} from 'bootstrap-vue'
import CardProfileReputationMetrics from '@/layouts/components/cards/meli/accounts/profile/CardProfileReputationMetrics.vue'
import CardProfileIdentification from '@/layouts/components/cards/meli/accounts/profile/CardProfileIdentification.vue'
import CardProfileCompany from '@/layouts/components/cards/meli/accounts/profile/CardProfileCompany.vue'
import CardProfileAddresses from '@/layouts/components/cards/meli/accounts/profile/CardProfileAddresses.vue'
import CardProfileShipping from '@/layouts/components/cards/meli/accounts/profile/CardProfileShipping.vue'
import CardProfileOthers from '@/layouts/components/cards/meli/accounts/profile/CardProfileOthers.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BTabs,
    BTab,
    CardProfileReputationMetrics,
    CardProfileIdentification,
    CardProfileCompany,
    CardProfileAddresses,
    CardProfileShipping,
    CardProfileOthers,
  },
  props: {
    account: {
      type: Object,
      default: () => { },
    },
    inherit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: {
        showTitle: false,
        showAccount: false,
        showInfo: false,
      },
      tabs: [
        {
          title: 'components.cards.meli_accounts.profile.tabs.reputation',
          icon: ['fas', 'laptop'],
          component: 'CardProfileReputationMetrics',
        },
        {
          title: 'components.cards.meli_accounts.profile.tabs.identification',
          icon: ['far', 'address-card'],
          component: 'CardProfileIdentification',
        },
        {
          title: 'components.cards.meli_accounts.profile.tabs.corporation',
          icon: ['fas', 'store'],
          component: 'CardProfileCompany',
        },
        {
          title: 'components.cards.meli_accounts.profile.tabs.addresses',
          icon: ['fas', 'map-marker-alt'],
          component: 'CardProfileAddresses',
        },
        {
          title: 'components.cards.meli_accounts.profile.tabs.shipping',
          icon: ['fas', 'truck'],
          component: 'CardProfileShipping',
        },
        {
          title: 'components.cards.meli_accounts.profile.tabs.others',
          icon: ['fas', 'ellipsis-h'],
          component: 'CardProfileOthers',
        },
      ],
      selectedAccount: null,
      unWatchSelectedAccount: null,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
  },
}
</script>
