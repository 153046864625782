<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="text-primary">
        <font-awesome-icon :icon="['fas', 'user-lock']" fixed-width /> {{ $t('meli.permissions.permissions') }}
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row v-if="permissions">
        <b-col v-for="item in permissions" :key="item.index" md="12" class="mt-2"
          :class="item.customClass">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="24" :variant="item.color">
                <font-awesome-icon :icon="item.icon" fixed-width />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="text-capitalize">
                {{ item.title }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
              <b-card-text v-if="item.codes.length > 0" class="font-small-3 mb-0">
                <span v-for="code in item.codes" :key="code" class="text-danger">- {{ code }}<br /></span>
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    account: {
      type: Object,
      default: () => { },
    },
    inherit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedAccount: {},
      unWatchSelectedAccount: null,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
  computed: {
    permissions() {
      if (this.selectedAccount && this.selectedAccount.seller) {
        return [
          {
            icon: this.selectedAccount.seller.status.list.allow ? ['fas', 'check'] : ['fas', 'times'],
            color: this.selectedAccount.seller.status.list.allow ? 'light-success' : 'light-danger',
            title: this.$i18n.t('meli.permissions.list'),
            subtitle: '',
            customClass: 'mb-2 mb-xl-0',
            codes: this.selectedAccount.seller.status.list.codes,
          },
          {
            icon: this.selectedAccount.seller.status.sell.allow ? ['fas', 'check'] : ['fas', 'times'],
            color: this.selectedAccount.seller.status.sell.allow ? 'light-success' : 'light-danger',
            title: this.$i18n.t('meli.permissions.sell'),
            subtitle: '',
            customClass: 'mb-2 mb-sm-0',
            codes: this.selectedAccount.seller.status.sell.codes,
          },
          {
            icon: this.selectedAccount.seller.status.buy.allow ? ['fas', 'check'] : ['fas', 'times'],
            color: this.selectedAccount.seller.status.buy.allow ? 'light-success' : 'light-danger',
            title: this.$i18n.t('meli.permissions.buy'),
            subtitle: '',
            customClass: '',
            codes: this.selectedAccount.seller.status.buy.codes,
          },
        ]
      }
      return []
    },
  },
}
</script>
