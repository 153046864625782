<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="text-primary">
        <font-awesome-icon :icon="['fas', 'truck']" fixed-width /> {{ $t('meli.shipping.enabled_shipping_modes') }}
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row v-if="selectedAccount && selectedAccount.seller">
        <b-col v-for="item in selectedAccount.seller.shipping_modes" :key="item.icon" md="12" class="mt-2">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="24" variant="light-success">
                <font-awesome-icon :icon="['fas', 'check']" fixed-width />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="text-capitalize mb-75">
                {{ $t('meli.shipping.modes.' + item) }}
              </h5>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    account: {
      type: Object,
      default: () => { },
    },
    inherit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedAccount: {},
      unWatchSelectedAccount: null,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
}
</script>
