<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="text-primary">
        <font-awesome-icon :icon="['fas', 'laptop']" fixed-width /> {{ $t('meli.reputation.reputation') }}
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row v-if="sellerData.transactions && sellerData.ratings">
        <b-col md="6">
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                Negociações
              </b-card-title>
            </b-card-header>
            <vue-apex-charts height="250" type="radialBar" :options="cardProfileReputationOptions.chartOptions" :series="transactionsSeries" />
            <b-row class="text-center mx-0">
              <b-col cols="6" class="border-top border-right d-flex align-items-between flex-column py-1">
                <b-card-text class="text-muted mb-0">
                  Completadas
                </b-card-text>
                <h3 class="font-weight-bolder mb-50">
                  {{ sellerData.transactions.completed }}
                </h3>
              </b-col>

              <b-col cols="6" class="border-top d-flex align-items-between flex-column py-1">
                <b-card-text class="text-muted mb-0">
                  Total
                </b-card-text>
                <h3 class="font-weight-bolder mb-0">
                  {{ sellerData.transactions.total }}
                </h3>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                Opiniões Positivas
              </b-card-title>
            </b-card-header>
            <vue-apex-charts height="250" type="radialBar" :options="cardProfileReputationOptions.chartOptions"
              :series="ratingsSeries" />
            <b-row class="text-center mx-0">
              <b-col cols="6" class="border-top border-right d-flex align-items-between flex-column py-1">
                <b-card-text class="text-muted mb-0">
                  Neutras
                </b-card-text>
                <h3 class="font-weight-bolder text-muted mb-0">
                  {{ sellerData.ratings.neutral }}
                </h3>
              </b-col>
              <b-col cols="6" class="border-top d-flex align-items-between flex-column py-1">
                <b-card-text class="text-danger mb-0">
                  Negativas
                </b-card-text>
                <h3 class="font-weight-bolder text-danger mb-0">
                  {{ sellerData.ratings.negative }}
                </h3>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import stringHelper from '@core-md/mixins/stringHelper'
import VueApexCharts from 'vue-apexcharts'
import cardProfileReputationOptions from './_cardProfileReputationOptions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    VueApexCharts,
  },
  props: {
    account: {
      type: Object,
      default: () => { },
    },
    inherit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  mixins: [
    stringHelper,
  ],
  data() {
    return {
      selectedAccount: {},
      unWatchSelectedAccount: null,
      cardProfileReputationOptions,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
  computed: {
    transactionsSeries() {
      const transactions = []
      if (this.selectedAccount && this.selectedAccount.seller && this.selectedAccount.seller.seller_reputation.transactions.total > 0) {
        transactions.push(this.formatNumber((this.selectedAccount.seller.seller_reputation.transactions.completed / this.selectedAccount.seller.seller_reputation.transactions.total) * 100, 0))
      } else {
        transactions.push(0)
      }
      return transactions
    },
    ratingsSeries() {
      const ratings = []
      if (this.selectedAccount && this.selectedAccount.seller) {
        ratings.push(this.formatNumber(this.selectedAccount.seller.seller_reputation.transactions.ratings.positive * 100, 0))
      }
      // ratings.push(this.selectedAccount.seller.seller_reputation.transactions.ratings.neutral)
      // ratings.push(this.selectedAccount.seller.seller_reputation.transactions.ratings.negative)
      return ratings
    },
    sellerData() {
      if (this.selectedAccount && this.selectedAccount.seller) {
        return {
          transactions: {
            completed: this.formatNumber(this.selectedAccount.seller.seller_reputation.transactions.completed, 0),
            total: this.formatNumber(this.selectedAccount.seller.seller_reputation.transactions.total, 0),
          },
          ratings: {
            neutral: this.formatNumber(this.selectedAccount.seller.seller_reputation.transactions.ratings.neutral * 100, 0, '', '%'),
            negative: this.formatNumber(this.selectedAccount.seller.seller_reputation.transactions.ratings.negative * 100, 0, '', '%'),
          },
        }
      }
      return { }
    },
  },
}
</script>
