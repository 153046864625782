<template>
  <b-card no-body v-if="sellerData" class="card-congratulation-medal">
    <b-card-header>
      <b-card-title class="text-primary">
        <b-img v-if="sellerData.logo" :src="sellerData.logo" style="max-width:80px; max-height: 80px" />
        <b-avatar v-else size="80" square variant="primary">
          <font-awesome-icon  :icon="['far', 'image']" size="4x" fixed-width />
        </b-avatar>
        {{ sellerData.nickname }} ({{ sellerData.points }})
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-card-text class="font-small-3">
        {{ sellerData.name }}
      </b-card-text>
      <b-card-text class="font-small-3">
        <b-img :src="sellerData.imgLevel" />
      </b-card-text>
      <b-card-text class="font-small-3">
        <span v-if="sellerData.powerSellerStatus" class="text-success"><font-awesome-icon :icon="['fas', 'medal']" class="mr-50" fixed-width /> Parabéns, você é um MercadoLíder<span
            v-if="sellerData.powerSellerStatus === 'platinum'"> Platinum</span>!</span>
      </b-card-text>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" v-bind:href="sellerData.permalink" target="_blank">
        <font-awesome-icon :icon="['fas', 'paper-plane']" class="mr-50" fixed-width /> Ver Perfil no ML
      </b-button>
      <b-img v-if="sellerData.powerSellerStatus === 'silver'"
        :src="require('@/assets/images/illustration/meli_power_seller_silver.svg')" class="congratulation-medal d-none d-md-block"
        alt="Mercado Líder" />
      <b-img v-if="sellerData.powerSellerStatus === 'platinum'"
        :src="require('@/assets/images/illustration/meli_power_seller_platinum.svg')" class="congratulation-medal d-none d-md-block"
        alt="Mercado Líder Platinum" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BButton, BImg, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BImg,
    BButton,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    inherit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedAccount: {},
      unWatchSelectedAccount: null,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
  computed: {
    sellerData() {
      if (this.selectedAccount && this.selectedAccount.seller) {
        return {
          id: this.selectedAccount.seller.id,
          nickname: this.selectedAccount.seller.nickname,
          points: this.selectedAccount.seller.points,
          name: `${this.selectedAccount.seller.first_name} ${this.selectedAccount.seller.last_name}`,
          logo: (this.selectedAccount.seller.thumbnail && this.selectedAccount.seller.thumbnail.picture_url) || '',
          imgLevel: `https://cdn.mdlivre.com.br/general/static/img/meli/level/${this.selectedAccount.seller.seller_reputation.level_id}_v2.png`,
          powerSellerStatus: this.selectedAccount.seller.seller_reputation.power_seller_status || '',
          permalink: this.selectedAccount.seller.permalink,
        }
      }
      return {}
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
