<template>
  <b-card class="profile-header mb-2" :img-src="require('@/assets/images/mdlivre/card_profile_meli_0.png')" img-top body-class="p-0">
    <!-- profile picture -->
    <div class="position-relative">
      <div class="profile-img-container d-flex align-items-center">
        <div class="profile-img">
          <b-img v-if="sellerData.logo" :src="sellerData.logo" rounded fluid alt="profile photo" />
          <b-avatar v-else size="115" square variant="primary">
            <font-awesome-icon :icon="['far', 'image']" size="6x" fixed-width />
          </b-avatar>
        </div>

        <!-- profile title -->
        <div class="profile-title ml-3">
          <h2 class="">
            {{ sellerData.nickname }}
          </h2>
          <p class="">
            {{ sellerData.name }}
          </p>
        </div>
        <!--/ profile title -->
      </div>
    </div>
    <!--/ profile picture -->

    <!-- profile navbar -->
    <div class="profile-header-nav">
      <b-navbar toggleable="md" type="light">
        <!-- toggle button -->
        <b-navbar-toggle class="ml-auto" target="nav-text-collapse">
          <feather-icon icon="AlignJustifyIcon" size="21" />
        </b-navbar-toggle>
        <!--/ toggle button -->

        <!-- collapse -->
        <b-collapse id="nav-text-collapse" is-nav>
          <b-tabs pills class="profile-tabs mt-1 mt-md-0" nav-class="mb-0">
            <!-- edit buttons -->
            <template #tabs-end>
              <b-button variant="primary" class="ml-auto">
                <font-awesome-icon :icon="['fas', 'cog']" fixed-width />
              </b-button>
            </template>
            <!-- edit buttons -->
          </b-tabs>

        </b-collapse>
        <!--/ collapse -->
      </b-navbar>
    </div>
    <!--/ profile navbar -->
  </b-card>
</template>

<script>
import {
  BCard, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BButton, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BTabs,
    BButton,
    BAvatar,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    account: {
      type: Object,
      default: () => { },
    },
    inherit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      selectedAccount: {},
      unWatchSelectedAccount: null,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
  computed: {
    sellerData() {
      if (this.selectedAccount) {
        return {
          nickname: this.selectedAccount.seller.nickname,
          name: `${this.selectedAccount.seller.first_name} ${this.selectedAccount.seller.last_name}`,
          logo: (this.selectedAccount.seller.thumbnail && this.selectedAccount.seller.thumbnail.picture_url) || '',
        }
      }
      return {}
    },
  },
}
</script>
