<template>
  <b-card>
    <b-card-header class="p-0">
      <b-card-title class="text-primary">
        <font-awesome-icon :icon="['fas', 'map-marker-alt']" fixed-width /> {{ $t('common.address.address') }}
      </b-card-title>
    </b-card-header>
    <div v-for="item in addressData" :key="item.title" class="mt-2">
      <h5 class="text-capitalize">
        {{ item.title }}
      </h5>
      <b-card-text>
        {{ item.value }}
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText,
} from 'bootstrap-vue'
import stringHelper from '@core-md/mixins/stringHelper'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
  },
  props: {
    account: {
      type: Object,
      default: () => { },
    },
    inherit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: () => { },
    },
  },
  mixins: [
    stringHelper,
  ],
  data() {
    return {
      selectedAccount: {},
      unWatchSelectedAccount: null,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
  computed: {
    addressData() {
      if (this.selectedAccount && this.selectedAccount.seller && this.selectedAccount.seller.address) {
        return [
          {
            title: this.$i18n.t('common.address.street'),
            value: this.selectedAccount.seller.address.address,
          },
          {
            title: this.$i18n.t('common.address.zip_code'),
            value: this.formatZipCode(this.selectedAccount.seller.address.zip_code),
          },
          {
            title: this.$i18n.t('common.address.city'),
            value: this.selectedAccount.seller.address.city,
          },
          {
            title: this.$i18n.t('common.address.state'),
            value: this.selectedAccount.seller.address.state.split('-')[1],
          },
          {
            title: this.$i18n.t('common.address.country'),
            value: this.selectedAccount.seller.address.state.split('-')[0],
          },
        ]
      }
      return []
    },
  },
}
</script>
