<template>
  <div>
    <b-card no-body>
      <HTMLTitle :title="$t('components.cards.meli_accounts.profile.page_title')"></HTMLTitle>

      <card-header :title="$t('components.cards.meli_accounts.profile.page_title')"
        :id="'meliProfile'"
        :icon="['fas', 'user']"
        :subTitle="(selectedAccount && selectedAccount.nickname) || $t('components.cards.common.select_account')"
        :description="$t('components.cards.meli_accounts.profile.page_info')"></card-header>
    </b-card>

    <full class="d-none d-md-block" :account="selectedAccount" />
    <tabs class="d-block d-md-none" :account="selectedAccount" />
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import CardHeader from '@core-md/components/cards/CardHeader.vue'
import Full from './Full.vue'
import Tabs from './Tabs.vue'

export default {
  components: {
    HTMLTitle,
    CardHeader,
    BCard,
    Full,
    Tabs,
  },
  props: {
    account: {
      type: Object,
      default: () => { },
    },
    inherit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: {
        showTitle: false,
        showAccount: false,
        showInfo: false,
      },
      selectedAccount: { },
      unWatchSelectedAccount: null,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
  },
}
</script>
