<template>
  <div id="user-profile">
    <card-profile-header v-if="false" />
    <b-row class="match-height">
      <b-col lg="6" cols="12" order="2" order-lg="1">
        <card-profile-level />
        <card-profile-metrics />
      </b-col>
      <b-col lg="6" cols="12" order="2" order-lg="1">
        <card-profile-reputation />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="3" cols="12">
        <card-profile-identification />
      </b-col>

      <b-col lg="3" cols="12">
        <card-profile-company />
      </b-col>

      <b-col lg="3" cols="12">
        <card-profile-addresses />
      </b-col>

      <b-col lg="3" cols="12">
        <card-profile-shipping />
        <card-profile-others />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import CardProfileHeader from '@/layouts/components/cards/meli/accounts/profile/CardProfileHeader.vue'
import CardProfileLevel from '@/layouts/components/cards/meli/accounts/profile/CardProfileLevel.vue'
import CardProfileReputation from '@/layouts/components/cards/meli/accounts/profile/CardProfileReputation.vue'
import CardProfileMetrics from '@/layouts/components/cards/meli/accounts/profile/CardProfileMetrics.vue'
import CardProfileIdentification from '@/layouts/components/cards/meli/accounts/profile/CardProfileIdentification.vue'
import CardProfileCompany from '@/layouts/components/cards/meli/accounts/profile/CardProfileCompany.vue'
import CardProfileAddresses from '@/layouts/components/cards/meli/accounts/profile/CardProfileAddresses.vue'
import CardProfileShipping from '@/layouts/components/cards/meli/accounts/profile/CardProfileShipping.vue'
import CardProfileOthers from '@/layouts/components/cards/meli/accounts/profile/CardProfileOthers.vue'

export default {
  components: {
    BRow,
    BCol,
    CardProfileHeader,
    CardProfileLevel,
    CardProfileReputation,
    CardProfileMetrics,
    CardProfileIdentification,
    CardProfileCompany,
    CardProfileAddresses,
    CardProfileShipping,
    CardProfileOthers,
  },
  props: {
    account: {
      type: Object,
      default: () => { },
    },
    inherit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: {
        showTitle: false,
        showAccount: false,
        showInfo: false,
      },
      selectedAccount: null,
      unWatchSelectedAccount: null,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-profile.scss';
</style>
