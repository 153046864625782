<template>
  <b-card>
    <b-card-header class="p-0">
      <b-card-title class="text-primary">
        <font-awesome-icon :icon="['far', 'address-card']" fixed-width /> {{ $t('meli.identification.title') }}
      </b-card-title>
    </b-card-header>
    <div v-for="item in addressData" :key="item.title" class="mt-2">
      <h5 class="text-capitalize">
        {{ item.title }}
      </h5>
      <b-card-text>
        {{ item.value }}
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText,
} from 'bootstrap-vue'
import stringHelper from '@core-md/mixins/stringHelper'
import { format } from 'date-fns'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
  },
  props: {
    account: {
      type: Object,
      default: () => { },
    },
    inherit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: () => { },
    },
  },
  mixins: [
    stringHelper,
  ],
  data() {
    return {
      selectedAccount: {},
      unWatchSelectedAccount: null,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
  computed: {
    addressData() {
      if (this.selectedAccount && this.selectedAccount.seller) {
        return [
          {
            title: this.$i18n.t('meli.identification.id'),
            value: this.selectedAccount.seller.id,
          },
          {
            title: this.$i18n.t('meli.identification.nickname'),
            value: this.selectedAccount.seller.nickname,
          },
          {
            title: this.$i18n.t('common.name'),
            value: `${this.selectedAccount.seller.first_name} ${this.selectedAccount.seller.last_name}`,
          },
          {
            title: this.$i18n.t('common.email'),
            value: this.selectedAccount.seller.email,
          },
          {
            title: this.$i18n.t('meli.identification.document'),
            value: `${this.selectedAccount.seller.identification.type} ${this.formatDocument(this.selectedAccount.seller.identification.number)}`,
          },
          {
            title: this.$i18n.t('meli.identification.primary_phone'),
            value: `${this.selectedAccount.seller.phone.area_code || ''} ${this.selectedAccount.seller.phone.number}`,
          },
          {
            title: this.$i18n.t('meli.identification.alternative_phone'),
            value: `${this.selectedAccount.seller.alternative_phone.area_code} ${this.selectedAccount.seller.alternative_phone.number}`,
          },
          {
            title: this.$i18n.t('meli.identification.registration_date'),
            value: format(new Date(this.selectedAccount.seller.registration_date), 'dd/MM/yyyy HH:mm:ss'),
          },
        ]
      }
      return []
    },
  },
}
</script>
